import * as React from 'react';
import {
  DateField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import {ImageListField} from './IncidentEdit';
import StatusField from './StatusField';
// import {Incident} from '../types';
// import CompanyField, {ContactField} from '../users/CompanyField';

export const IncidentShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateField source="ts" />
      <StatusField source="status" show />
      <TextField source="status" />


      <Labeled>
        <ReferenceField reference="users" source="uid" label="Reporter">
          <>
            <TextField source="company" />&nbsp;
            <TextField source="branch" />
          </>
        </ReferenceField>
      </Labeled>

      <TextField source="name" />
      <TextField source="role" />
      <TextField source="phone" />

      <TextField source="type" />
      <TextField source="model" />
      <TextField source="description" />
      <TextField source="preferredTime" />
      <ImageListField source="images" />
    </SimpleShowLayout>{' '}
  </Show>
);
