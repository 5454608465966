import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  BooleanInput,
} from 'react-admin';

export const NewsEditForm = (props: any) => (
  <SimpleForm {...props}>
    <TextInput source="id" />
    <DateInput source="ts" />
    <TextInput source="title" />
    <TextInput source="head" multiline rows={3}/>
    <TextInput source="body" multiline rows={10} />
    <TextInput source="url" />
    <BooleanInput source="published" />
    <TextInput source="image" />
  </SimpleForm>
);
const NewsEdit = () => (
  <Edit>
    <NewsEditForm />
  </Edit>
);

export default NewsEdit;
