import GoogleIcon from '@mui/icons-material/Google';
import {Button, Container, Divider} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as React from 'react';
import {Admin, Login, LoginForm, Resource} from 'react-admin';
import ReactDOM from 'react-dom/client';
import {useNavigate} from 'react-router-dom';

import incidents from './incidents';
import './index.css';
import users from './users';
import news from './news';
import {app, authProvider, dataProvider} from './firebase';

const root = ReactDOM.createRoot(document.getElementById('root')!);

// const auth = authProvider();
console.log("AAS")
const auth = app.auth();
const provider = new firebase.auth.GoogleAuthProvider();

const MyLogin = () => {
  const navigate = useNavigate();
  const signInWithGoogle = () => {
    console.log('GOOG');
    // return auth.signInWithRedirect(provider)
    return auth.signInWithPopup(provider)
      .then(() => navigate('/'))
      .catch((err: any) => console.log(err));
  };

  return (
    <Login>
      <LoginForm />
      <Divider />
      <Container>
        <Button variant="contained" onClick={signInWithGoogle}>
          <GoogleIcon />
          Sign in with Google
        </Button>
      </Container>
    </Login>
  );
};

root.render(
  // <React.StrictMode>
  <div>
    <Admin
      title="Luknár Servis"
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={MyLogin}>
      {/*<Resource name="news" list={ListGuesser} />*/}
      <Resource {...incidents} />
      <Resource {...users} />
      <Resource {...news} />
    </Admin>
    {/*<h1>HELLO</h1>*/}
    {/*<div>*/}
    {/*  <button className="button" onClick={signInWithGoogle}>*/}
    {/*    <i className="fab fa-google" />*/}
    {/*    Sign in with google*/}
    {/*  </button>*/}
    {/*</div>*/}
  </div>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
