import * as React from 'react';
import Box, {BoxProps} from '@mui/material/Box';
import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import {News} from '../types';

const ImgField = ({source, ...props}: {source: keyof News} & BoxProps) => {
  const record = useRecordContext<News>();
  if (!record) {
    return null;
  }
  return (
    <Box {...props}>
      <img src={record[source] as string} />
    </Box>
  );
};

const NewsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="ts" />
      <TextField source="title" />
      <TextField source="head" />
      <TextField source="body" />
      <TextField source="url" />
      <BooleanField source="published" />
      <ImgField source="image" sx={{
        maxWidth: 120,
        maxHeight: 120,
        img: {maxWidth: 120}}} />
    </SimpleShowLayout>
  </Show>
);

export default NewsShow;
