import {Theme, useMediaQuery} from '@mui/material';
import firebase from 'firebase/compat/app';
import {RowClickFunction} from 'ra-ui-materialui';
import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  ImageField,
  NullableBooleanInput,
  List,
  ShowButton,
  SimpleList,
  TextField,
  useRefresh,
  TextInput,
} from 'react-admin';

import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Uncheck from '@mui/icons-material/Close';


function getUserFilters() {
  return [
    <TextInput source="title" alwaysOn />,
    <NullableBooleanInput source="published" alwaysOn />,
    <DateInput source="ts " alwaysOn />,
  ];
}

const Right = () => <ChevronRight />;

const NewsList = () => {
  const refresh = useRefresh();
  const isSmall = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down('sm'));
  let edit = false;
  const tick: RowClickFunction = (id, resource, record) => {
    if (edit) {
      edit = false;
      console.log('CLICK', {id, resource, record}, !!firebase);
      firebase
        .firestore()
        .collection(resource)
        .doc(id.toString())
        // .get()
        .update({published: !record.published})
        .then(() => {
          record.published = !record.published;
          refresh();
        })
        .catch(console.error);
      return '';
    } else {
      return 'edit';
    }
  };

  return (
    <List
      filters={getUserFilters()}
      sort={{field: 'ts', order: 'DESC'}}
    >
      {isSmall
        ? (<SimpleList
          primaryText={rec => rec.title}
          secondaryText={rec => rec.secondaryText}
          leftAvatar={rec => rec.url}
          rightIcon={Right}
          linkType="show"
        />)
        : (
        <Datagrid rowClick={tick}>
          <TextField source="title" />
          <TextField source="head" />
          <DateField source="ts" />
          <ImageField
            source="image"
            textAlign="center"
            sx={{
              maxWidth: 60,
              maxHeight: 50,
              img: {maxWidth: 100, maxHeight: 30},
            }}
          />
          {/*<TextField source="body" />*/}
          <TextField source="url"  sx={{
            maxWidth: 120,
            display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
            ":hover": {backgroundColor: 'white',  overflow: 'visible', position: 'relative', top: 0, zIndex: 1000, whiteSpace: 'unset'}
          }} />
          <BooleanField
            source="published"
            onClick={() => (edit = true)}
            FalseIcon={Uncheck}
            TrueIcon={Check}
          />

          {true && (
            <>
              <EditButton />
              <ShowButton />white-space: unset
            </>
          )}
        </Datagrid>
      )}
    </List>
  );
};

export default NewsList;
