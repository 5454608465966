import {ShowGuesser} from 'react-admin';
import UserIcon from '@mui/icons-material/Person';
import UserEdit from './UserEdit';
import UserList from './UserList';

export default {
  name: 'users',
  icon: UserIcon,
  list: UserList,
  edit: UserEdit,
  show: ShowGuesser,
};
