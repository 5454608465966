import * as React from 'react';
import {Create} from 'react-admin';
import {NewsEditForm} from './NewsEdit';

const NewsEdit = () => (
  <Create>
    <NewsEditForm />
  </Create>
);

export default NewsEdit;
