import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { User } from '../types';

const CompanyField = () => {
  const record = useRecordContext<User>();

  return record ? (
    <span>
          {record.company}, {record.branch}
    </span>
  ) : null;
};

export const ContactField = () => {
  const record = useRecordContext<User>();

  return record ? (
    <span>
          {record.name}, {record.role}
    </span>
  ) : null;
};



export default CompanyField;
