import ChevronRight from '@mui/icons-material/ChevronRight';
import {Theme, useMediaQuery} from '@mui/material';
import firebase from "firebase/compat/app";
import {useRecordContext, useResourceContext} from "ra-core";
import {RowClickFunction} from 'ra-ui-materialui';
import * as React from 'react';
import {
  AutocompleteInput,
  Button,
  Datagrid,
  EditButton,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  TextInput,
  SelectInput,
  ShowButton,
  SimpleList,
  TextField, useRefresh,
} from 'react-admin';
import {Incident, User} from '../types';
import CompanyField, {ContactField} from '../users/CompanyField';
import {STATUSES} from './IncidentEdit';
import StatusField, {STATUS_COLORS} from './StatusField';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const statuses: Incident['status'][] = ['fresh', 'reviewed', 'planed', 'resolved'];
const statusSucc = Object.fromEntries(statuses.slice(0, -1).map((s, i) => [s, statuses[i + 1]]))

const getUserFilters = (isSmall: boolean) => {
  return [
    <SelectInput source="status" choices={STATUSES} alwaysOn />,
    <TextInput source="type" label="Type"  alwaysOn={!isSmall} />,
    <TextInput source="model" label="Model" alwaysOn={!isSmall} />,
    <TextInput source="company" label="Company"  alwaysOn={!isSmall} />,
  ];
};

export const OrderBy = () => <ReferenceInput source="uid" reference="users">
  <AutocompleteInput
    optionText={(choice?: User) =>
      choice?.id // the empty choice is { id: '' }
        ? `${choice.name} ${choice.company}`
        : ''
    }
  />
</ReferenceInput>;


const NextButton = (props: any) => {
  const refresh = useRefresh();
  const resource = useResourceContext(props);
  const record = useRecordContext<Incident>(props);
  const newStatus = statusSucc[record.status];

  // console.log('123', resource, record);
  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        if (!newStatus) {
          return;
        }
        record.status = newStatus;
        firebase
          .firestore()
          .collection(resource)
          .doc(record.id)
          .update({status: newStatus});
        console.log('XXX', props);
        refresh();
      }}
      disabled={record.status === 'resolved'}
      style={{color: STATUS_COLORS[newStatus]}}
      label={`Next: ${newStatus}`}>
      {<KeyboardDoubleArrowLeftIcon />}
    </Button>
  );
};

const IncidentList = (props: ListProps) => {
  const isSmall = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down('sm'));
  const rowClick: RowClickFunction = (id, resource, record) => {
    console.log({id, resource, record});
    return 'show';
  };

  return (<List
    filters={getUserFilters(isSmall)}
    sort={{field: 'ts', order: 'DESC'}}
    perPage={20}
    {...props}
    title="Incidents"
    >
    {isSmall ? (
        <SimpleList primaryText={record => record.company}
                    secondaryText={record => `${record.model} views`}
                    // tertiaryText={record => record.ts}
                    leftIcon={() => <div><StatusField /></div>}
                    rightIcon={() => <ChevronRight />}
                    linkType="show"
        />)
      : (
        <Datagrid
          rowClick={rowClick} optimized>
          <StatusField source="status" />
          <TextField source="ts" label="Date"/>
          <TextField source="type" />
          <TextField source="model" />
          <TextField source="preferredTime" />
          <ReferenceField
            source="uid"
            reference="users"
              label="Contact"
          >
            <ContactField />
          </ReferenceField>

          <ReferenceField
            source="uid"
            reference="users"
            link={false}
            label="Customer"
          >
            <CompanyField />
          </ReferenceField>

          {true && (<>
            <EditButton />
            <ShowButton />
            <NextButton />
          </>)}
        </Datagrid>)}
  </List>);
};

export default IncidentList;
