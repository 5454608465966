import NewsCreate from './NewsCreate';
import NewsEdit from './NewsEdit';
import NewsList from './NewsList';
import NewsShow from './NewsShow';

export default {
  name: 'news',
  list: NewsList,
  show: NewsShow,
  edit: NewsEdit,
  create: NewsCreate,
};
