import {Box, ImageList, ImageListItem, Modal} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {
  Edit,
  Labeled,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import {Incident} from '../types';

export const STATUSES = [
  {id: 'fresh', name: 'New'},
  {id: 'reviewed', name: 'Reviewed'},
  {id: 'planed', name: 'Planed'},
  {id: 'resolved', name: 'Resolved'},
];

export const PREFERRED_TIME = [
  {id: 'anytime', name: 'Anytime'},
  {id: 'morning', name: 'Morning'},
  {id: 'afternoon', name: 'Afternoon'},
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ImageListField = ({source}: {source: keyof Incident}) => {
  const record: Incident = useRecordContext();
  if (!record) {
    throw new Error('NO RECORD');
  }

  const [imageUrls, setImageUrls] = useState([] as string[]);
  const [open, setOpen] = useState('');

  useEffect(() => {
    const images = record[source] as string[] ?? [];

    Promise.all(
      images.map(img => firebase.storage().ref(img).getDownloadURL()),
    ).then(urls => setImageUrls(urls));
  }, [record, source]);

  return (
    <>
      <ImageList cols={2}>
        {imageUrls.length === 0 ? <Box>No images</Box> : undefined}
        {imageUrls.map(item => (
          <ImageListItem key={item} onClick={() => setOpen(item)}>
            <img src={item} />
          </ImageListItem>
        ))}
      </ImageList>
      <Modal open={!!open} onClose={() => setOpen('')}>
        <Box sx={style} component="img" src={open} />
      </Modal>
    </>
  );
};

const IncidentEdit = () => {
  return (
    <Edit title="">
      <SimpleForm>
        <SelectInput source="status" choices={STATUSES} />
        <TextInput source="type" />
        <TextInput source="model" />
        <TextInput source="description" multiline rows={5} />
        <SelectInput
          source="preferredTime"
          translateChoice
          choices={PREFERRED_TIME}
        />

        <ImageListField source="images" />

        <Labeled>
          <ReferenceField reference="users" source="uid" label="Reporter">
            <TextField source="company" />
          </ReferenceField>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export default IncidentEdit;
