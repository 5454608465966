import {FirebaseAuthProvider, FirebaseDataProvider} from 'react-admin-firebase';
import {RAFirebaseOptions} from 'react-admin-firebase';
import firebase from 'firebase/compat/app';

const config = {
  apiKey: 'AIzaSyDcMB35CZT02jdeK6P5WtpREfR9XF2j1u8',
  authDomain: 'luknar-test.firebaseapp.com',
  projectId: 'luknar-test',
  storageBucket: 'luknar-test.appspot.com',
  messagingSenderId: '522275578394',
  appId: '1:522275578394:web:a3c841ae30f9baa8d627a3',
};

export const app = firebase.initializeApp(config);
// const config = {
//   projectId: 'luknar-test',
//   apiKey: "HELLO",
//   appId: "app:007:web:123",
//   authDomain: "localhost",
//   databaseURL: "http://localhost:8080",
// };
//
// export const app = firebase.initializeApp(config);
// export const app = initializeApp(config);
// console.log({app});
// export const auth = getAuth(app);
// console.log({auth});

// All options are optional
const options: RAFirebaseOptions = {
  //     // Use a different root document to set your resource collections, by default it uses the root collections of firestore
  //     rootRef: 'root-collection/some-doc' | () => 'root-collection/some-doc',
  //     // Your own, previously initialized firebase app instance
  app,
  //     // Enable logging of react-admin-firebase
  logging: true,
  //     // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
  //     watch: ['posts'],
  //     // Resources you explicitly dont want realtime updates for
  //     dontwatch: ['comments'],
  //     // Authentication persistence, defaults to 'session', options are 'session' | 'local' | 'none'
  //     persistence: 'session',
  //     // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
  //     disableMeta: false,
  //     // Have custom metadata field names instead of: 'createdate', 'lastupdate', 'createdby', 'updatedby'
  //     renameMetaFields: {
  //     created_at: 'my_created_at', // default: 'createdate'
  //         created_by: 'my_created_by', // default: 'createdby'
  //         updated_at: 'my_updated_at', // default: 'lastupdate'
  //         updated_by: 'my_updated_by', // default: 'updatedby'
  // },
  // }
  // // Prevents document from getting the ID field added as a property
  // dontAddIdFieldToDoc: false,
  //     // Adds 'deleted' meta field for non-destructive deleting functionality
  //     // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true}
  //     softDelete: false,
  //     // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
  //     associateUsersById: false,
  //     // Casing for meta fields like 'createdby' and 'updatedby', defaults to 'lower', options are 'lower' | 'camel' | 'snake' | 'pascal' | 'kebab'
  //     metaFieldCasing: 'lower',
  //     // Instead of saving full download url for file, save just relative path and then get download url
  //     // when getting docs - main use case is handling multiple firebase projects (environments)
  //     // and moving/copying documents/storage files between them - with relativeFilePaths, download url
  //     // always point to project own storage
  //     relativeFilePaths: false,
  //     // Add file name to storage path, when set to true the file name is included in the path
  //     useFileNamesInStorage: false,
  //     // Use firebase sdk queries for pagination, filtering and sorting
  //     lazyLoading: {
  //     enabled: false
  // },
  // // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  // firestoreCostsLogger: {
  //     enabled: false,
  //         localStoragePrefix // optional
  // },
  // // Function to transform documentData before they are written to Firestore
  // transformToDb: (resourceName, documentData, documentId) => documentDataTransformed
};

// Configure FirebaseUI.
// export const uiConfig = {
//   // Popup signin flow rather than redirect flow.
//   signInFlow: 'popup',
//   // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '#/',
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [
//     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//     firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//   ],
//   // Optional callbacks in order to get Access Token from Google,Facebook,... etc
//   callbacks: {
//     signInSuccessWithAuthResult: (result: any) => {
//       const credential = result.credential;
//       // The signed-in user info.
//       const user = result.user;
//       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//       const accessToken = credential.accessToken;
//       console.log({result, user, accessToken});
//     },
//   },
// };

export const dataProvider = FirebaseDataProvider(config, options);
export const authProvider = FirebaseAuthProvider(config, options);
// const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);
