import {Box, Typography, Grid} from '@mui/material';
import * as React from 'react';
import {
  BooleanInput,
  CloneButton,
  Edit,
  NullableBooleanInput,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar,
} from 'react-admin';
import {User} from '../types';

const EditActions = ({
  data,
}: {
  basePath?: string;
  hasShow?: boolean;
  data: User;
}) => (
  <TopToolbar>
    <CloneButton
      className="button-clone"
      // basePath={basePath}
      record={data}
    />
    <ShowButton record={data} />
    {/*<PasswordButton basePath={basePath} record={data} />*/}
  </TopToolbar>
);

console.log(EditActions);

const UserEdit = () => {
  return (
    <Edit title="">
      <SimpleForm>
        {/*<div style={{background: "blue"}}>*/}
        <Grid container width={{xs: '100%', md: '100%'}} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Company details
            </Typography>
            <Box display={{xs: 'block', sm: 'flex'}}>
              <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                <TextInput source="company" isRequired fullWidth />
              </Box>
              <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                <TextInput source="branch" isRequired fullWidth />
              </Box>
            </Box>
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={8}>*/}
            <Typography variant="h6" gutterBottom>
              Contact details
            </Typography>
            <Box display={{xs: 'block', sm: 'flex'}}>
              <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                <TextInput source="name" isRequired fullWidth />
              </Box>
              <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                <TextInput source="role" isRequired />
              </Box>
            </Box>
            <Box mt="1em" />
            <Box display={{xs: 'block', sm: 'flex'}}>
              <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                <TextInput source="email" isRequired type="email" fullWidth />
              </Box>
              <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                <TextInput source="phone" isRequired fullWidth type="tel" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6" gutterBottom>
              Stats
            </Typography>

            <NullableBooleanInput fullWidth source="registered" />
            <BooleanInput fullWidth source="shared" />

          </Grid>
        </Grid>
        {/*</div>*/}
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
