import ChevronRight from '@mui/icons-material/ChevronRight';
import UserIcon from '@mui/icons-material/People';
import {
  // Button,
   Theme, useMediaQuery} from '@mui/material';
import {RowClickFunction} from 'ra-ui-materialui';
import {memo} from 'react';
import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  EmailField,
  List,
  ListProps,
  NullableBooleanInput,
  TextInput,
  ShowButton,
  SimpleList,
  TextField,
  // useRecordContext,
} from 'react-admin';
// import {app} from "../firebase";
// import {firebase} from 'firebase/compat/app';

// const MakeAdmin = () => {
//   // const resource = useResourceContext();
//   const record = useRecordContext();
//   const makeAdmin = () => {
//     console.log(record);
//     app.auth().currentUser?.getIdToken()
//       .then(console.log)
//   }
//   return (<Button onClick={makeAdmin}>
//     Make Admin {record.id}
//   </Button>)
// }
const UIC = () => <UserIcon />;
const getUserFilters = () => {
  return [
    <TextInput source="company" alwaysOn label="Company" />,
    <NullableBooleanInput source="shared"  alwaysOn />,
  ];

};

const Right = () => <ChevronRight />;

const UserList = (props: ListProps) => {
  const isSmall = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down('sm'));

  const rowClick: RowClickFunction = (id, resource, record) => {
    console.log({id, resource, record});
    return 'show';
  };

  const SmallList = memo(() => <SimpleList primaryText={record => record.company} secondaryText={record => `${record.name} views`}
    // tertiaryText={record => record.role}
                                leftIcon={UIC}
                                rightIcon={Right}
                                linkType="show"
  />);

  return (<List
    filters={getUserFilters()}
    sort={{field: 'company', order: 'ASC'}}
    perPage={20} {...props} >
    {isSmall
      ? <SmallList />
      : <Datagrid
         rowClick={rowClick} optimized>
          <TextField source="company" />
          <TextField source="branch" />
          <TextField source="name" />
          <TextField source="role" />
          <EmailField source="email" />
          <TextField source="phone" />
          <BooleanField source="registered" />
          <BooleanField source="shared" />
          <BooleanField source="gdpr" />
          <>
            <EditButton />
            <ShowButton />
          </>
        </Datagrid>}
  </List>);
};

// const ListView = () => {
//   const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
//
//   return <div>{isSmall}</div>;
// };

export default UserList;
