import {Tooltip} from '@mui/material';
import {CSSProperties} from 'react';
import * as React from 'react';
import {useRecordContext} from 'react-admin';
// import LensIcon from '@mui/icons-material/Lens';
import {Incident} from '../types';

export const STATUS_COLORS: {[id in Incident['status']]: string} = {
  fresh: '#ff0000',
  reviewed: '#ff7a00',
  planed: '#00ff00',
  resolved: '#aaa',
};

const StatusField = ({show = false, style = { textAlign: show ? 'left' : 'center' }}: {show?: boolean, style? : CSSProperties}) => {
  const record: Incident = useRecordContext();
  if (!record) {
    return null;
  }
  const cls = record.status;
  const styles: {[idx: string]: CSSProperties} = {
    icon: {
      backgroundColor: STATUS_COLORS[cls],
      height: 16,
      width: 16,
      borderRadius: 10,
      position: 'relative',
      top: 3,
      display: 'inline-block',
    },
    text: {
      display: 'inline-block',
      marginLeft: 4,
    },
  };

  return (
    <Tooltip title={cls}>
      <div style={style}>
        <div style={styles.icon} />
        <div style={styles.text}>{show ? record.status : undefined}</div>
      </div>
    </Tooltip>
  );
};

StatusField.defaultProps = {
  source: 'error',
};

export default StatusField;
