import LocalFireDep from '@mui/icons-material/LocalFireDepartment';
import IncidentEdit from './IncidentEdit';
import IncidentList from './IncidentList';
import {IncidentShow} from './IncidentShow';

export default {
  name: 'incidents',
  icon: LocalFireDep,
  list: IncidentList,
  edit: IncidentEdit,
  show: IncidentShow,
};
